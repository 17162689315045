import { template } from '@shein/common-function'
import schttp from '@/public/src/services/schttp'
import { percentNum, percentNumWithoutSign } from '@/public/src/pages/common/promotion/utils/common.js'
import Cache from './cache'

const handlePromotionMap = {
  // 买折
  1: (language, promotionInfo, promotionRange) => {
    const info = promotionInfo.rules[0]
    const isHit = promotionRange.range >= 1
    if (isHit) {
      return template(info.value, percentNumWithoutSign(info.discount.value), language.SHEIN_KEY_PWA_26698)
    }
    return ''
  },
  // 买N折N
  9: (language, promotionInfo, promotionRange) => {
    const info = promotionInfo.rules[promotionRange.range - 1]
    return template(info.value, percentNumWithoutSign(info.discount.value), language.SHEIN_KEY_PWA_26699)
  },
  // 满减 type 1: 满件; 3: 满元;
  14: (language, promotionInfo, promotionRange) => {
    const info = promotionInfo.rules[promotionRange.range - 1]
    const discountValueAmountWithSymbol = info.discount?.value_amount?.amountWithSymbol
    const buyLimitAmountWithSymbol = info.value_amount?.amountWithSymbol
    const buyLimitAmount = info.value
    if (info.type == 3 && info.discount?.type == 6) {
      return template(buyLimitAmountWithSymbol, discountValueAmountWithSymbol, language.SHEIN_KEY_PWA_26704)
    } else if (info.type == 1 && info.discount?.type == 6) {
      return template(buyLimitAmount, discountValueAmountWithSymbol, language.SHEIN_KEY_PWA_26703)
    }
  },
  // X元N件 type 1: 满x件; 2: 每满X件 discount.type 7: M元任选X件
  15: (language, promotionInfo, promotionRange) => {
    const isNoGear = [2, 4].includes(promotionInfo?.rules[0]?.type)
    const info = isNoGear ? promotionInfo.rules[0] : promotionInfo.rules[promotionRange.range - 1]
    const isHit = isNoGear ? promotionRange.range >= 1 : !!info
    if (isHit) {
      return template(info.discount?.value_amount?.amountWithSymbol, info.value, language.SHEIN_KEY_PWA_26702)
    }
    return ''
  },
  // 满金额打折 discount.type 3: 按特价折扣; 1: 按原价折;
  25: (language, promotionInfo, promotionRange) => {
    const info = promotionInfo.rules[promotionRange.range - 1]
    return template(info.value_amount?.amountWithSymbol, percentNum(info.discount?.value), language.SHEIN_KEY_PWA_26701)
  },
}

class Save {
  constructor({ language, key }) {
    this.language = language
    this.typeIds = [1, 9, 14, 15, 25] // 参与利诱点信息的活动类型
    this.lastData = null
    this.cache = new Cache(key || 'cart_tag_tips__save')
  }

  async getData(saveInfo, config = {}) {
    const {
      diffCache = true
    } = config

    const {
      total_discount_price = {},
      coupon_infos = [],
      promotion_discount_info = [],
      promotion_range,
    } = saveInfo || {}
    const {
      amount,
      amountWithSymbol
    } = total_discount_price || {}

    if (amount > 0) {
      const lastData = diffCache && this.cache.data
      const couponTotal = coupon_infos.length
      const promotionTotal = promotion_discount_info.filter(f => this.typeIds.includes(f.typeId)).length
      const promotionTypeIds = promotion_discount_info.map(m => m.typeId)

      this.cache.set({
        amount,
        couponTotal,
        promotionTotal,
        promotionTypeIds,
      })

      let tag = `-${amountWithSymbol}`
      if (tag.length > 7) tag = 'SAVE'
      let tips = false

      if (
        !lastData || // 没有缓存数据
        (
          +amount > +lastData.amount && // 总优惠金额增加
          (
            couponTotal > lastData.couponTotal || // 购物车最优优惠券数量增加
            promotionTotal > lastData.promotionTotal // 指定促销活动的满足数量增加
          )
        )
      ) {
        const maxPromotion = this.getMaxPromotion(promotion_discount_info, lastData)
        const maxCoupon = this.getMaxCoupon(coupon_infos)
        const language = await this.language

        if (+(maxCoupon?.discount_price?.amount || 0) > +(maxPromotion?.discount_price?.amount || 0)) {
          tips = language.SHEIN_KEY_PWA_26512
        } else if (maxPromotion) {
          const res = await this.getPromotionById(maxPromotion)
          const promoTips = handlePromotionMap[maxPromotion.typeId](language, res, promotion_range.find(f => f.promotion_id === maxPromotion.id))
          tips = promoTips ? language.SHEIN_KEY_PWA_26700.replace('{0}', promoTips) : ''
        }
      }

      return {
        tag,
        tips,
      }
    } else {
      this.cache.set(null)

      return {
        tag: false,
        tips: false,
      }
    }
  }

  getMaxPromotion(promotion_discount_info, lastData) {
    const promotionList = promotion_discount_info
      .filter(f => this.typeIds.includes(f.typeId)) // 过滤非需求活动
      .filter(f => !lastData || !lastData.promotionTypeIds.includes(f.typeId)) // 过滤缓存中存在的活动

    if (!promotionList.length) return null

    const maxPromotion = promotionList.reduce((acc, cur) => {
      const accAmount = +acc?.discount_price?.amount
      const curAmount = +cur?.discount_price?.amount
      // 如果金额相等，则判断活动 id
      if (accAmount === curAmount) return acc.id > cur.id ? acc : cur
      // 返回金额最大的活动
      return accAmount > curAmount ? acc : cur
    })

    return maxPromotion
  }

  getMaxCoupon(coupon_infos) {
    if (!coupon_infos.length) return null

    const maxCoupon = coupon_infos.reduce((acc, cur) => {
      const accAmount = +acc?.discount_price?.amount
      const curAmount = +cur?.discount_price?.amount
      return accAmount > curAmount ? acc : cur
    })
    return maxCoupon
  }

  async getPromotionById(promotionInfo) {
    const res = await schttp({
      url: '/api/cart/postGetPromotionById/get',
      method: 'POST',
      data: {
        promotion_id: [promotionInfo.id]
      }
    })
    const info = res?.info?.list?.[0]
    if (!info) return null
    return info
  }
}

export default Save
