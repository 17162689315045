import schttp from '@/public/src/services/schttp'

export const getPromotionById = async promotionInfo => {
  const res = await schttp({
    url: '/api/cart/postGetPromotionById/get',
    method: 'POST',
    data: {
      promotion_id: [promotionInfo.id]
    }
  })
  const info = res?.info?.list?.[0]
  if (!info) return null
  return info
}

export const getPromotionByIds = async (ids) => {
  if (!ids?.length) return []
  const res = await schttp({
    url: '/api/cart/postGetPromotionById/get',
    method: 'POST',
    data: {
      promotion_id: ids
    }
  })
  const list = res?.info?.list
  if (!list?.length) return []
  return list
}
