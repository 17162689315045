import { isLogin } from '@/public/src/pages/common/utils/index.js'

class Cache {
  constructor(key, { initCallback,  setCallback } = {}) {
    this.key = key
    this.infoKey = key + '/info'
    this.initCallback = initCallback
    this.setCallback = setCallback

    this.data = undefined
    this.init()
  }

  init() {
    if (typeof window === 'undefined') return
    const dataInfo = JSON.parse(window.sessionStorage.getItem(this.infoKey))
    // 站点、币种、登录状态变更时，清除缓存
    if (dataInfo && (
      dataInfo.langPath !== gbCommonInfo.langPath ||
      dataInfo.currency !== gbCommonInfo.currency ||
      dataInfo.isLogin !== isLogin())
    ) {
      window.sessionStorage.removeItem(this.key)
    }
    window.sessionStorage.setItem(this.infoKey, JSON.stringify({
      langPath: gbCommonInfo.langPath,
      currency: gbCommonInfo.currency,
      isLogin: isLogin(),
    }))
    this.data = JSON.parse(window.sessionStorage.getItem(this.key))

    this.initCallback?.(this.data)
  }

  get() {
    return this.data
  }

  set(val) {
    this.data = val
    window.sessionStorage.setItem(this.key, JSON.stringify(val))

    this.setCallback?.(val)
  }

  clear() {
    window.sessionStorage.removeItem(this.key)
    window.sessionStorage.removeItem(this.infoKey)
  }
}

export default Cache
