import { template } from '@shein/common-function'
import Cache from './cache'
import { getPromotionByIds } from './getPromotion'

class Gift {
  constructor({ language, key }) {
    this.language = language
    this.typeIds = [2, 4, 28] // 参与利诱点信息的活动类型
    this.cache = new Cache(key || 'cart_tag_tips__gift')
  }

  async getData(giftInfo, config = {}) {
    const {
      diffCache = true,
      isNewuserStyle = false
    } = config

    const { promotion_range, item_list } = giftInfo || {}

    // 满足门槛的赠品活动
    const giftPromotion = promotion_range?.filter(f => f.range > 0 && this.typeIds.includes(f.type_id))

    // getPromotionByIds
    const status = !!giftPromotion?.length
    const lastStatus = diffCache && this.cache.data
    const language = await this.language

    let tips = false
    let identify = ''
    let cartItemList = []
    let lurePointItemHasChecked = '0'

    if (!lastStatus && status) {
      if (diffCache) {
        tips = language.SHEIN_KEY_PWA_26511
      } else {
        const promotionInfos = await getPromotionByIds(giftPromotion.map(m => m.promotion_id))
        const maxPromotion = this.getMaxPromotion(promotionInfos, giftPromotion)
        const newuserTag = !!promotionInfos.find(f => f.promotion_logo_type === 4)
        const giftItemList = this.getGiftItemList(item_list) // 赠品
        const checkedGfitItemList = this.getCheckedItemList(giftItemList) // 勾选的赠品

        cartItemList = checkedGfitItemList

        const isNewuserTip = isNewuserStyle && newuserTag
        if (!checkedGfitItemList?.length) {
          // 没有选择赠品
          tips = isNewuserTip ? language.SHEIN_KEY_PWA_33199 : language.SHEIN_KEY_PWA_33200
          identify = maxPromotion.promotion_id
        } else if (checkedGfitItemList.length === 1) {
          tips = isNewuserTip ? language.SHEIN_KEY_PWA_33196 : language.SHEIN_KEY_PWA_33197
          lurePointItemHasChecked = '1'
        } else {
          tips = isNewuserTip ? template(checkedGfitItemList.length, language.SHEIN_KEY_PWA_33198) : language.SHEIN_KEY_PWA_33197
          lurePointItemHasChecked = '1'
        }
      }
    }

    this.cache.set(status)

    return {
      tag: status,
      isPopover: !diffCache,
      identify,
      lurePointItemHasChecked,
      tips,
      cartItemList,
    }
  }

  getMaxPromotion(promotionInfos, giftPromotion) {
    let promos
    // 全场满赠
    if (!promos) promos = promotionInfos.filter(promo => promo.type_id === 4)
    // 满赠（非全场 有品牌）
    if (!promos) promos = promotionInfos.filter(promo => promo.type_id === 28)
    // 买赠
    if (!promos) promos = promotionInfos.filter(promo => promo.type_id === 2)

    let maxPromo
    promos.forEach(promo => {
      const rangeInfo = giftPromotion.find(f => f.promotion_id === promo.promotion_id)
      if (!maxPromo) {
        maxPromo = {
          ...promo,
          range: rangeInfo.range,
        }
      } else {
        const amount = promo.rules[rangeInfo.range - 1]?.value_amount.amount
        const maxPromoAmount = maxPromo.rules[maxPromo.range - 1]?.value_amount.amount
        if (+amount > +maxPromoAmount) {
          maxPromo = {
            ...promo,
            range: rangeInfo.range,
          }
        }
      }
    })

    return maxPromo
  }

  getGiftItemList(itemList) {
    return itemList.filter(item => !!item.product_promotion_info.find(f => this.typeIds.includes(f.type_id) && [1, 8].includes(+f.promotion_product_type)))
  }

  getCheckedItemList(itemList) {
    return itemList.filter(f => f.is_checked === 1)
  }
}

export default Gift
