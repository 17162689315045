import Cache from './cache'

class Free {
  constructor({ language, key, turnOnCouponFree }) {
    this.turnOnCouponFree = turnOnCouponFree || false // 是否开启 免邮券规则

    this.language = language
    // 免邮方式优先级 高 -> 低
    this.priority = ['express_shoptransit', 'shop_transit', 'urgent_express', 'standard_express', 'postage']
    this.cache = new Cache(key || 'cart_tag_tips__free')
    if (turnOnCouponFree) this.cache4coupon = new Cache(key ? `${key}__coupon` : 'cart_tag_tips__free4coupon')
  }

  async getData({ freeInfo, promoteSalesInfo, cartBriefInfo } = {}, config = {}) {
    const {
      diffCache = true
    } = config

    if (!cartBriefInfo?.item_list?.length) {
      this.cache.set(null)
      if (this.turnOnCouponFree) this.cache4coupon.set(null)
      return {
        tag: false,
        tips: false,
      }
    }

    const freeMap = this.getFreeListMap(freeInfo)
    let couponArray
    if (this.turnOnCouponFree) couponArray = this.getCouponListArray(promoteSalesInfo)

    const keys = Object.keys(freeMap)
    // 没有符合条件的免邮数据
    if (!keys.length && (!this.turnOnCouponFree || !couponArray.length)) {
      this.cache.set(null)
      if (this.turnOnCouponFree) this.cache4coupon.set(null)
      return false
    }

    const language = await this.language


    let freeMapFilter = {}
    if (diffCache && this.cache.data) {
      // 过滤掉上一次满足的免邮方式
      keys.forEach(key => {
        if (this.cache.data[key]) return
        freeMapFilter[key] = freeMap[key]
      })
    } else {
      freeMapFilter = freeMap
    }

    this.cache.set(freeMap)

    // 有新增的 免邮方式
    if (Object.keys(freeMapFilter).length) {
      // 获取最高展示优先级的免邮方式
      const type = this.priority.find(f => freeMapFilter[f])
      let tips = false
      if (type) tips = language.SHEIN_KEY_PWA_26510.replace('{0}', freeMapFilter[type].shipping_method_name)
      return {
        tag: true,
        category: 'activity',
        tips,
      }
    }

    if (this.turnOnCouponFree) {
      let couponArrayFilter = []
      if (diffCache && this.cache4coupon.data) {
        // 过滤掉上一次满足的免邮券
        couponArrayFilter = couponArray.filter(coupon => !this.cache4coupon.data.find(f => f.coupon_code === coupon.coupon_code))
      } else {
        couponArrayFilter = couponArray
      }

      this.cache4coupon.set(couponArray)

      // 有新增的 免邮券
      // 免邮券优先级最高
      if (couponArrayFilter.length) {
        // 获取返回的第一张免邮券
        const coupon = couponArrayFilter[0]
        // 获取最高展示优先级的免邮方式
        const type = this.priority.find(m => coupon.shipping_list.find(n => n.shipping_method_code === m))
        let tips = false
        if (type) {
          const target = coupon.shipping_list.find(f => f.shipping_method_code === type)
          if (target) tips = language.SHEIN_KEY_PWA_29841.replace('{0}', target.shipping_method_name)
        }
        return {
          tag: true,
          category: 'coupon',
          tips,
        }
      }
    }

    // 没有新增的 免邮方式 和 免邮券
    return {
      tag: true,
      tips: false,
    }
  }

  // 获取满足条件的免邮方式
  getFreeListMap(freeInfo) {
    const { platform_transport_free_list, mall_transport_free_list } = freeInfo || {}
    if (!platform_transport_free_list && !mall_transport_free_list) return false
    const freeList = {}
    if (platform_transport_free_list) {
      platform_transport_free_list.forEach?.(f => {
        if (f?.is_free_shipping !== 1) return
        if (freeList[f.transport_type]) return
        freeList[f.transport_type] = f
      })
    }
    if (mall_transport_free_list) {
      mall_transport_free_list.forEach?.(mall => {
        mall?.transport_free_list?.forEach?.(f => {
          if (f?.is_free_shipping !== 1) return
          if (freeList[f.transport_type]) return
          freeList[f.transport_type] = f
        })
      })
    }
    return freeList
  }

  // 获取满足条件的免邮券
  getCouponListArray(promoteSalesInfo) {
    const couponList = []
    if (promoteSalesInfo?.coupons?.forEach) {
      promoteSalesInfo.coupons.forEach(coupon => {
        if (coupon.shipping_discount_type === 0 && coupon.satisfied_range === 1) {
          couponList.push(coupon)
        }
      })
    }
    return couponList
  }
}

export default Free
